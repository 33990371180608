import { Box } from "@chakra-ui/react";
import HeaderNavigation from "../../../private/shared/navs/HeaderNavigation";
import PageHeaderLeftPseudoElementsTypeOne from "../../../private/shared/UI/PageHeaderLeftPseudoElementsTypeOne";
import PageHeaderRightPseudoElementsTypeOne from "../../../private/shared/UI/PageHeaderRightPseudoElementsTypeOne";
import ScrollToTop from "../../../private/shared/UI/ScrollToTop";
import FooterNavigation from "../navs/FooterNavigation";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// used for public-> membership, faq, terms, privacy
const LayoutMembership = ({
  children,
  pTop,
  bgColor,
  scheme,
  invertPseudoElements,
}) => {
  // const location = useLocation();

  // useEffect(() => {
  //   // Push route change to GTM dataLayer
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: "pageview",
  //     title: document.title,
  //     page: location.pathname + location.search, // Full path including query parameters
  //   });
  // }, [location]);

  return (
    <Box position="relative" overflow="hidden" bgColor="backgroundGrey">
      <ScrollToTop />
      <PageHeaderLeftPseudoElementsTypeOne
        invertPseudoElements={invertPseudoElements}
      />
      <PageHeaderRightPseudoElementsTypeOne
        invertPseudoElements={invertPseudoElements}
      />
      <HeaderNavigation />
      <Box pt={pTop || 0}>{children}</Box>
      <FooterNavigation scheme={scheme} bgColor={bgColor} />
    </Box>
  );
};

export default LayoutMembership;
