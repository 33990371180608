import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Fonts from "./theme/fonts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactGA from "react-ga4";

ReactGA.initialize("G-GL2ZETWZ2B"); // Replace with your Measurement ID

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // 4 ways to prevent auto-data-refetch
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      // trigger data fetch regardless if data is stale or fresh
      //refetchInterval: 1000,
      retry: 1,
      // time after data status changes from fresh to stale and data can be fetched again
      staleTime: 5 * 1000,
      // data is removed from cache if component is unmounted or inactive time exceeds
      // cacheTime: 1000 * 60 * 5, // default time to become inactive
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={client}>
    <ChakraProvider resetCSS theme={theme}>
      <Fonts />
      <App />
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </ChakraProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
