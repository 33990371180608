import { Flex } from "@chakra-ui/react";
import Footer from "../../components/public/home/Footer";
import Landing from "../../components/public/home/Landing";
import NumberedSection from "../../components/public/home/NumberedSection";
import RemoveTheRisksSection from "../../components/public/home/RemoveTheRisksSection";
import HowItWorksSection from "../../components/public/home/HowItWorksSection";
import searchSecX3src from "../../assets/images/public/home/search_candidate_x3.webp";
import searchSecFallbacksrc from "../../assets/images/public/home/search_candidate_x3.png";
import communicateSecX3src from "../../assets/images/public/shared/communicate_x3.webp";
import communicateSecFallbacksrc from "../../assets/images/public/shared/communicate_x3.png";
import hireSecX1src from "../../assets/images/public/home/hire_x1.webp";
import hireSecX3src from "../../assets/images/public/home/hire_x3.webp";
import hireSecFallbacksrc from "../../assets/images/public/home/hire_x3.png";
import findSecX3src from "../../assets/images/public/shared/find_candidate_x3.webp";
import findSecFallbacksrc from "../../assets/images/public/shared/find_candidate_x3.png";
import ceoSecX1src from "../../assets/images/public/home/ceo.webp";
import ceoSecX3src from "../../assets/images/public/home/ceo.webp";
import ceoSecFallbacksrc from "../../assets/images/public/home/ceo.png";

const HomePage = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="full"
      minH="100vh"
      overflow="hidden"
    >
      <Landing />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="37.5rem"
        textBlockSize="34.0625rem"
        gap={{ base: "3rem", md: "5rem", xl: "7.875rem" }}
        imageFirst
        twoLine
        lineOneText="Recruit"
        lineTwoText="top"
        lastWord="talent"
        bodyText="Find the perfect candidate in the pool of pre-screened finance talents. Communicate directly with chosen candidates. Intelligently select top candidates for your business-critical roles anytime, anywhere."
        srcX1={findSecX3src}
        srcX2={findSecX3src}
        srcX3={findSecX3src}
        fallbackSrc={findSecFallbacksrc}
        pseudoElementsTypeOne
      />
      <RemoveTheRisksSection />
      <HowItWorksSection />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="37.5rem"
        textBlockSize="34.0625rem"
        gap={{ base: "3rem", md: "5rem", xl: "6.875rem" }}
        stepNumber="01"
        lastWord="Search"
        bodyText="Use unbiased, robust search and filtering options to find the perfect candidates who are actively looking for a new role."
        srcX1={searchSecX3src}
        srcX2={searchSecX3src}
        srcX3={searchSecX3src}
        fallbackSrc={searchSecFallbacksrc}
        hasTopElement
        pseudoElementsTypeTwo
      />
      <NumberedSection
        bgColor="white"
        imageBlockSize="42.6875rem"
        textBlockSize="34.0625rem"
        gap={{ base: "3rem", md: "5rem", xl: "6.875rem" }}
        imageFirst
        stepNumber="02"
        lastWord="Communicate"
        bodyText="Have direct communication with chosen candidates. Once a candidate accepts the invitation, gain access to their full profile."
        srcX1={communicateSecX3src}
        srcX2={communicateSecX3src}
        srcX3={communicateSecX3src}
        fallbackSrc={communicateSecFallbacksrc}
      />
      <NumberedSection
        bgColor="backgroundGrey"
        imageBlockSize="42.6875rem"
        textBlockSize="31.875rem"
        gap={{ base: "3rem", md: "5rem", xl: "3.9375rem" }}
        stepNumber="03"
        lastWord="Hire"
        bodyText="Control the hiring process from content to timescales. Hire candidates with no recruitment fees."
        srcX1={hireSecX1src}
        srcX2={hireSecX3src}
        srcX3={hireSecX3src}
        fallbackSrc={hireSecFallbacksrc}
      />
      <NumberedSection
        bgColor="white"
        imageBlockSize="22.3rem"
        textBlockSize="34.0625rem"
        gap={{ base: "3rem", md: "5rem", xl: "12.25rem" }}
        stepNumber=""
        imageFirst
        lastWord="our story"
        bodyText="We spotted an opportunity to change the way finance hires are made in the UK recruitment market. Removing the risk of making a bad hire, and unnecessary recruitment fees."
        srcX1={ceoSecX1src}
        srcX2={ceoSecX3src}
        srcX3={ceoSecX3src}
        fallbackSrc={ceoSecFallbacksrc}
        ourStorySection
      />
      <Footer />
    </Flex>
  );
};

export default HomePage;
