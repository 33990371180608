import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  VStack,
  useBreakpointValue
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import FormSuccessfulySent from "../../components/public/shared/FormSuccessfulySent";
import { candidateRegisterFn } from "../../services/apiServices";
import { getErrorResponsePayload } from "../../utils/helpers";
import HearAboutUsSelectInput from "../../components/public/shared/HearAboutUsSelectInput";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your full name"),
  email: Yup.string()
    .required("Please enter your email address")
    .email("Please enter a valid email address"),
  phoneNumber: Yup.string().required("Please enter your phone number"),
  jobTitle: Yup.string().required("Please enter your job title"),
  hearAboutUs: Yup.string().required("Please choose one option"),
});

const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onBlur",
};

function JoinAsCandidatePage() {
  const [successfulMessageSend, setSuccessfulMessageSend] = useState(false);
  const [sourceField, setSourceField] = useState(null);
  const hearAboutMenuPlacement = useBreakpointValue({ base: "auto", sm: "top" })

  useEffect(() => {
    if (sourceField !== null) {
      setValue("hearAboutUs", sourceField.value);
      clearErrors("hearAboutUs");
    }
  }, [sourceField]);

  // const sourceFieldErrorHandler = () => {
  //   if (!sourceField) {
  //     setError("hearAboutUs", "Please enter notice period");
  //   } else {
  //     clearErrors("hearAboutUs");
  //   }
  // };

  const sourceOnChangeHandler = (item) => {
    setSourceField(item);
    clearErrors("hearAboutUs");
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    clearErrors,
  } = useForm(formOptions);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => candidateRegisterFn(data),
    onSuccess: (data) => {
      setSuccessfulMessageSend(true);
    },
    onError: (error) => {
      const { errors } = getErrorResponsePayload(error);

      if (errors?.name?.errors) {
        const message = errors.name.errors[0];
        setError("name", { type: "server", message });
      }
      if (errors?.email?.errors) {
        const message = errors.email.errors[0];
        setError("email", { type: "server", message });
      }
      if (errors?.phoneNumber?.errors) {
        const message = errors.phoneNumber.errors[0];
        setError("phoneNumber", { type: "server", message });
      }
      if (errors?.jobTitle?.errors) {
        const message = errors.jobTitle.errors[0];
        setError("message", { type: "server", message });
      }
    },
  });

  const onSubmit = async (data) => {
    mutate(data);
  };

  if (successfulMessageSend)
    return <FormSuccessfulySent form="joinAsCandidate" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing={8} alignItems="flex-start">
        <Box pb="0.5rem">
          <Heading
            as="h1"
            alignSelf={{ base: "center", sm: "flex-start" }}
            fontSize={{ base: "2.5rem", sm: "3.125rem" }} //50px
            lineHeight={{ base: "3rem", sm: "3.9rem" }} //63px
            mb="1.3125rem"
          >
            Join for Free
          </Heading>

          <Text
            fontFamily="Plus Jakarta Sans Regular"
            color="darkGrey"
            fontSize={{ base: "1.125rem" }}
            lineHeight={{ base: "1.4375rem" }}
          >
            Fill out the form below and we will be in touch soon to request your
            CV.
          </Text>
        </Box>

        <FormControl isRequired isInvalid={errors.name}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="name"
            requiredIndicator=""
            mb="0.5rem"
          >
            Full name
          </FormLabel>
          <Input
            id="name"
            type="text"
            placeholder="Enter your full name"
            {...register("name")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.name?.message || errors.name}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.email}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="email"
            requiredIndicator=""
            mb="0.5rem"
          >
            Email address
          </FormLabel>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email address"
            {...register("email")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.email?.type === "server" && (
              <Text>
                An account with this email address already exists.
                <Link as={RouterLink} to="/login" color="limeText">
                  {" "}
                  Sign in.
                </Link>
              </Text>
            )}
            {errors.email?.type !== "server" && (
              <Text>{errors.email?.message}</Text>
            )}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.phoneNumber}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="phoneNumber"
            requiredIndicator=""
            mb="0.5rem"
          >
            Phone number
          </FormLabel>
          <Input
            id="phoneNumber"
            type="number"
            placeholder="Enter your phone number"
            {...register("phoneNumber")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.phoneNumber?.message || errors.phoneNumber}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.jobTitle}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="jobTitle"
            requiredIndicator=""
            mb="0.5rem"
          >
            Job title
          </FormLabel>
          <Input
            id="jobTitle"
            type="text"
            placeholder="Enter your job title"
            {...register("jobTitle")}
          />
          <FormErrorMessage fontSize="12px">
            {errors.jobTitle?.message || errors.jobTitle}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.hearAboutUs}>
          <FormLabel
            textStyle="bodySemiBold"
            fontWeight="600"
            htmlFor="hearAboutUs"
            requiredIndicator=""
            mb="0.5rem"
          >
            How did you hear about us?
          </FormLabel>
          <Input type="hidden" {...register("hearAboutUs")} />
          <HearAboutUsSelectInput
            onChange={sourceOnChangeHandler}
            value={sourceField}
            errorBorder={errors.hearAboutUs}
            isSearchable={false}
            blurInputOnSelect={false}
            //onBlur={sourceFieldErrorHandler}
            menuPlacement={hearAboutMenuPlacement}
          />
          <FormErrorMessage fontSize="12px">
            {errors.hearAboutUs?.message || errors.hearAboutUs}
          </FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          w="full"
          isLoading={isSubmitting || isLoading}
          disabled={!isDirty || !isValid || isSubmitting || isLoading}
          alt="Send message"
        >
          Join
        </Button>
      </VStack>
    </form>
  );
}

export default JoinAsCandidatePage;
